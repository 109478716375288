export const Testimonials = (props) => {
  return (
    <div id='testimonials'>
      <div className='container'>
        <div className='section-title text-center'>
          <h2>Программы</h2>
        </div>
        
        <div className='col-md-6'>
        <h3>Знакомство с горнолыжным инвентарем.</h3>
        <div className="row">
                  <div className='testimonial'>
                  <div className='testimonial-meta'>1. Первые шаги</div>
                    <div className='col'>
                    <p>1. Перенос инвентаря.</p><p>2. Повороты на месте.</p><p>3. Перемещение по ровному месту.</p><p>4. Подъем в гору.</p><p>5. Умение падать и вставать.</p>
            </div>
            </div>

            <div className='testimonial'>
                <div className='testimonial-meta'>2. Прямые и косые спуски</div>
                <div className='col'>
                    <p>1. Стойка.</p>
                    <p>2. Стойка прямого и косого спуска.</p>
                    <p>3. Косой спуск.</p>
                    <p>4. Торможение плугом на пологом склоне.</p>
                    <p>5. Боковое соскальзывание.</p>
            </div>
            </div>

            <div className='testimonial'>
            <div className='testimonial-meta'>3. Начальные повороты</div>
            <div className='col'>
                    <p>1. Повороты плугом (скользящий, резаный).</p>
                    <p>2. Отлогие повороты на пологом склоне.</p>
                    <p>3. Серия поворотов к склону из упора (гирлянда).</p>
                    <p>4. Повороты из упора (скользящий, резаный).</p>
                    <p>5. Простейший поворот на параллельных лыжах.</p>
            </div>
            </div>


            <div className='testimonial'>
            <div className='testimonial-meta'>4. Классификация поворотов</div>
            <div className='col'>
                    <p>1. Скользящие повороты на параллельных лыжах:</p>
                    <ul>
                      <li>Виды разгрузки. Скругленные соскальзывания большого, среднего и малого радиуса (винтовое движение, разворот ступней (бракаж), вращательный бросок).</li>
                      <li>Виды соскальзываний.</li>
                      <li>Способы торможения и остановки.</li>
                      <li>Торможение соскальзыванием.</li>
                      <li>Скользящий поворот на параллельных лыжах большого, среднего и малого радиуса.</li>
                    </ul>

                    <p>2. Комбинированный поворот (резаные повороты):</p>
                    <ul>
                      <li>Подготовительные упражнения для резаных поворотов.</li>
                      <li>Резаный поворот большого радиуса.</li>
                      <li>Резаный поворот среднего радиуса на пологом склоне.</li>
                      <li>Упражнения на угловую стойку и боковое равновесие.</li>
                    </ul>
                    </div>

            </div>

            <div className='testimonial'>
            <div className='testimonial-meta'>5. Совершенствование скользящих и комбинированных поворотов на параллельных лыжах</div>
            <div className='col'>
                    <p>1. Короткие скользящие сопряженные повороты.</p>
                    <p>2. Совершенствование техники резаных поворотов.</p>
                    <p>3. Совершенствование резаного поворота среднего радиуса.</p>
                    <p>4. Совершенствование резаного поворота среднего радиуса.</p>
                    <p>5. Боковой бросок.</p>
            </div>
            </div>


            </div>    
            
            </div> 
            <div className='col-md-6'>
        <h3>Знакомство со сноубордическим инвентарем.</h3>
        <div className="row">
                  <div className='testimonial'>
                  <div className='testimonial-meta'>1. Первые шаги</div>
                    <div className='col'>
                    <p>1. Перенос инвентаря.</p><p>2. Повороты на месте.</p><p>3. Перемещение по ровному месту.</p><p>4. Подъем в гору.</p><p>5. Умение падать и вставать.</p>
            </div>
            </div>

            <div className='testimonial'>
                <div className='testimonial-meta'>2. Прямые и косые спуски</div>
                <div className='col'>
                    <p>1. Стойка.</p>
                    <p>2. Прыжки с пристегнутой доской на плоскости.</p>
                    <p>3. Прямое скольжение на пологом спуске 1 пристегнутой ногой.</p>
                    <p>4. Закантовка доски.</p>
                    <p>5. Прямые соскальзывания.</p>
                    <p>6. Косое соскальзывание Траверс и косой спуск.</p>

                    <p>7. Гирлянда косых соскальзываний.</p>

                    <p>8. Торможение.</p>
                    <p>9. Торможение.</p>
                    <p>10. Скругленные соскальзывания.</p>

            </div>
            </div>

            <div className='testimonial'>
            <div className='testimonial-meta'>3. Начальные повороты</div>
            <div className='col'>
                    <p>1. J-поворот с соскальзыванием.</p>
                    <p>2. Резаный J-поворот.</p>
                    <p>3. J-гирлянда.</p>
                    <p>4. Сопряженные J-повороты.</p>
                    <p>5. Подъем на подъемнике.</p>
            </div>
            </div>



            <div className='testimonial'>
            <div className='testimonial-meta'>3. Классификация поворотов</div>
            <div className='col'>
                    <p>1. Виды разгрузки.</p>
                    <p>2. Скользящий поворот.</p>
                    <p>3. Поворот, сочетающий в себе боковое проскальзывание с резаным ведением.</p>
                    <p>4. (Комбинированные) подготовительные элементы к резаным поворотам.</p>
                    <p>5. Резаные повороты.</p>
            </div>
            </div>

            <div className='testimonial'>
            <div className='testimonial-meta'>5. Совершенствование скользящих и комбинированных поворотов на параллельных лыжах</div>
            <div className='col'>
                    <p>1. Короткие скользящие сопряженные повороты.</p>
            </div>
            </div>

            <div className='testimonial'>
            <div className='testimonial-meta'>6. Совершенствование резаного поворота среднего радиуса</div>
            <div className='col'>
                    <p>1. Резаные повороты среднего радиуса на склоне средней крутизны.</p>
                    <p>2. Короткие резаные сопряженные повороты.</p>
            </div>
            </div>

            </div>    
            
            </div> 

            
            </div>
            </div>

            
  )
}
